import * as React from 'react';
import { graphql } from 'gatsby';

import SecondaryPageLayout from '../components/secondary-page-layout';
import Meta from '../components/meta';
import { CookiePolicyPageTitle } from '../localisation';
import { Url } from '../url';

type QueryData = {
  iubendaDocument: {
    cookiePolicy: {
      content: string,
    },
  },
};

function CookiePolicyPage({ data }: { data: QueryData }) {
  const { iubendaDocument: { cookiePolicy } } = data;

  /* eslint-disable react/no-danger */
  return (
    <SecondaryPageLayout>
      <Meta
        title={CookiePolicyPageTitle}
        url={Url.CookiePolicy}
      />
      <div dangerouslySetInnerHTML={{ __html: cookiePolicy.content }} />
    </SecondaryPageLayout>
  );
  /* eslint-enable react/no-danger */
}

export const query = graphql`
  query CookiePolicyPageQuery {
    iubendaDocument {
      cookiePolicy {
        content
      }
    }
  }
`;

export default CookiePolicyPage;
